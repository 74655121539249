.module-layout,.module-collections

  &.error
    padding 20px 19px 13px

  .search_tabs,
  .notSearch_tabs,
  .notSearch_tabs_genre
    display flex
    align-items center
    .tablet &
    .mobile &
      overflow-x scroll
      margin-right 20px
      &::-webkit-scrollbar
        display none
    > *
      margin-right 8px
    .tab
      background #1CD397
      padding 6px 24px
      border-radius 35px
      cursor pointer
      &._related_artists
        .tablet &
        .mobile &
          min-width 165px
      &._related_albums
      &._related_collections
        .tablet &
        .mobile &
          min-width 140px
      &._disabled
        opacity .5
        user-select none
        @media (min-width 1030px)
          &:hover
            background #1CD397
            cursor default
      &.activated_tab,
      &.activated_tab_notSearch
        background #190A5C
        cursor pointer
      @media (min-width 1030px)
        &:hover
          background #190A5C
          cursor pointer
      span
        white-space nowrap
        font-weight 500
        font-size 16px
        line-height 16px
        color #fff
      .mobile &
        font-size 12px
  .notSearch_tabs,
  .notSearch_tabs_genre
    margin 0 10px
    max-width 100%
    overflow hidden
    flex-wrap wrap
    > *
      margin-bottom 8px
    .mobile &
      margin 0

  h1
    margin 0 27px 9px
    display inline-block
    font-size 30px
    font-weight bold
    line-height 35px

  .headlineLine
    display flex
    align-items flex-start
    &.coll
      margin-bottom 20px
    h1
      min-width 220px
      .tablet &
        margin-right 0
      .mobile &
        max-width 100px
        height unset
    @media only screen and (min-device-width 320px) and (max-device-width 900px) and (orientation landscape)
      justify-content space-evenly
    @media (max-width 500px)
      flex-wrap wrap
      justify-content space-between

  .songs
    margin-bottom 1.45em
    clear both
    padding 0

    >.item
    >.itemSpecial
      //position relative
      cursor pointer
      overflow hidden
      min-height 30px
      display flex
      align-items center

      flex-flow row nowrap
      &.active,
      &:hover
        box-shadow 0px 1px 6px #1e7b5c50
        background-color #EDFFF9
      &.no_file
        opacity 0.1
        filter unquote('alpha(opacity=10)')

        .actions
          display none

      &.load
        opacity 0.5
        filter unquote('alpha(opacity=50)')

      //@media only screen and (max-width : 500px) and (orientation: portrait)
      //  padding 11px 20px 14px 74px


      .actions
        //float left
        //inline-block()

        ul
          line-height 0
          display flex
          align-items center
          flex-flow row nowrap
          li
            //vertical-align middle
            cursor pointer
            margin 5px
            inline-block()

        .play
          background url(/images/sprite2_mp3ha.svg) -47px -106px
          width 60px
          height @width
          margin 0
          .playlistImg
            display none
            background none

          &:active,
          &:hover
            background url(/images/sprite2_mp3ha.svg) -47px -106px
            .playlistImg > img
              display none
          .playlistImg > img
            width 100%
            border-radius 4px
      .desc
        display block
        overflow hidden
        margin-right auto
        span.desc_wrapper
          margin 5px
          font-size 1em
          font-weight bold
          display block
          .track
            display block
            overflow hidden
            -ms-text-overflow ellipsis
            text-overflow ellipsis
            font-weight normal
            opacity 0.7
            filter unquote('alpha(opacity=70)')
      .download
        background url(/images/sprite2_mp3ha.svg) -360px 0px
        min-width 29px
        height 29px
        margin-right 5px
        &:hover
          background url(/images/sprite2_mp3ha.svg) -360px -60px
        &:active
          background url(/images/sprite2_mp3ha.svg) -360px -60px
        .dl
          display block
          height 100%
      &.active
        .playlistImg > img
          display none
        .actions
          .play
            background url(/images/sprite2_mp3ha.svg) -107px -107px

        &.pause
          .actions
            .play
              background url(/images/sprite2_mp3ha.svg) -47px -107px

      &.load
        opacity 0.5
        filter unquote('alpha(opacity=50)')
        .play
          position relative
          background url(/images/spiner.gif) 50% 50% no-repeat !important
      &.error
        opacity 0.5
        filter unquote('alpha(opacity=50)')
        .play
          position relative
          background url(/images/error.png) 50% 50% no-repeat !important


      &.active,
      &:hover
        .duration
          opacity 1
          filter unquote('alpha(opacity=100)')
      .duration
        opacity 0.8
        font-size 0.8em
        filter unquote('alpha(opacity=80)')
        margin 0 15px

        @media only screen and (max-width : 450px) and (orientation: portrait)
          display none

      li
        display inline-block
        vertical-align middle
        /*margin 0 5px*/

  .like
  .dislike
    width 30px
    height 30px
    margin 0
    opacity 0.3
    &:hover
      opacity 0.6
    &.activated
      opacity 1
      &:hover
        opacity 1
  .votes
    color $song-list-active-bg-color
    font-size 12px
    min-width 30px
    padding 0 0 0 10px
    margin 0 -10px
    &.voteDis
      color red
    &.voteLike
      color green

  .like_actions
    display flex
    flex-flow row nowrap
    align-items center
    .like
      background url(/images/likes.png) -10px -10px
      &:hover
        background url(/images/likes.png) -60px -10px

    .dislike
      background url(/images/likes.png) -10px -60px
      &:hover
        background url(/images/likes.png) -60px -60px

    &.active
      background #2ddda3
      color $song-list-active-text-color
      .desc
        span.desc_wrapper
          color $song-list-active-text-color

      .votes
        color $song-list-active-text-color

      .like
        background url(/images/likes.png) -110px -10px
        &:hover
          background url(/images/likes.png) -160px -10px

      .dislike
        background url(/images/likes.png) -110px -60px
        &:hover
          background url(/images/likes.png) -160px -60px

.addedSuccess,
.addedSuccessRadio,
.addedSuccessCollections
  position fixed
  bottom 80px
  margin-left 230px
  width 100%
  max-width 330px
  text-align center
  padding 10px 30px
  background #1cd397
  user-select none
  pointer-events none
  opacity 0
  z-index 999
  @media (max-width 1030px)
    margin-left 0
  @media (max-width 500px)
    padding 10px 0
    max-width unset
    left 0
    text-overflow ellipsis
    overflow hidden
  @media (max-width 320px)
    padding 10px
    max-width 300px
  span
    color #fff
    font-weight 500
    font-size 16px
    line-height 20px
    @media (max-width 320px)
      max-width 310px
  &._now
    opacity 1
    z-index 999
    transition all 0.5s linear
  .playlistPage &
    margin-left 380px

.module-collections
  .collectionsTile
    display flex
    flex-flow wrap
    justify-content space-between
    li
      width 136px
      max-height 200px
      overflow hidden
      margin 0 0 20px
    a
      background none
      width 136px
      padding 0
      &:hover
        background none
      img
        width 136px
        height @width
        border-radius 4px
    .title
      color #000
      font-size 14px
      line-height 16px
  .votes,.like_actions,.like,.dislike
    display none
  .songs
    margin-bottom 0!important
    .item
      width 100%
      .actions
        ul
          li
            &.download
              display none
            a
              padding 0

      .desc
        margin 0
        span.desc_wrapper
          span
            display block
        .delimiter
          display none
      .duration
        display none
  .titleWidgets
    display flex
    justify-content space-between
    align-items center
    padding 0
    span
      font-style normal
      font-weight bold
      font-size 18px
      line-height 21px
      display block
      margin 5px 0 12px
    a
      display flex
      align-items center
      color #000000
      border-bottom 1px solid #000000
      img
        width 22px
        height 30px
        margin-right 8px
      h3
        color #4D32C8
        border-bottom 1px solid rgba(77, 50, 200, .7)


.module-layout .songs
  margin-left 15px
  margin-right 15px

.mainArtists
.mainAlbums
.mainRadio
  li.item
    margin-top 0

.module-layout
  .notSearchContent
  .mainSearchSongs
  .mainAlbums
  .mainArtists
  .mainCollections
  .mainRelatedArtists
  .mainRelatedAlbums
  .mainRelatedCollections
  .mainRadio
    &._hidden
      display none!important
  .responseTabs
    .notSearch_items
      .plateItems
        li
          margin-top 20px
          margin-right 15px
          .tablet &
            margin-right 45px
          .mobile &
            margin-right unset
      .collections_items
        margin-bottom 40px
        .audio_collections_grid
          margin-bottom 12px
          .mobile &
            margin-bottom 0

.responseTabs
  .notSearch_items
    &.genreContent
      margin-top 30px
      .mobile &
        margin 20px 10px
      li
        .tablet &
          margin-right 45px
        .mobile &
          margin-right unset
    .plateItems
      .mobile &
        text-align unset
        display flex
        justify-content space-between
        flex-wrap wrap


.shared_buttons
  display inline-block
  float right
  margin 3px 8px
  align-items center
  min-width 200px
  &> div
    display inline-block
  @media (max-width 500px)
    float unset
    height 30px!important
    margin 10px 0!important
    min-width calc(100vw - 20px)
    &> div
      display block
    .ya-share2__container_size_m
      .ya-share2__list
        display none
body.mobile
  div.module-layout
    padding 25px 6px 0 6px
    &._tabs
      padding 0
    h1
      margin 0
      font-size 24px
      height 24px
      line-height 24px
    .shared_buttons
      height 24px
      margin 0
      padding-bottom 5px
      &._hide
        display none
      @media only screen and (orientation landscape)
        height 35px
        margin-top 10px
      &>div
        margin 0
    .songs
      margin 0
      padding 0
      margin-top 10px
      .artist
      .track
        display block
        max-height 20px
        overflow hidden
      .duration
        display block
        margin 0 5px 0 0
        font-size .8em
        @media (max-width 560px)
          display none
      h3
        line-height 0.5em
        .track, .artist
          width 100%
          line-height 1.2em
          max-height @line-height
          overflow hidden
          display inline-block
          text-overflow ellipsis
          white-space nowrap
    .box
      padding 0

.sidebarContainer
  overflow hidden
  padding 0 20px 20px 0

  &__header
    font-style normal
    font-weight 700
    font-size 18px
    line-height 21px
    color #000
    margin 5px 0 12px
    display block

.collectionTiles
  display flex
  flex-direction row
  flex-wrap nowrap
  &__item
    margin 0 4px 10px
  &_widget &
    &__item
      width calc(50% - 8px)

.collectionTile
  display block
  max-width 100%
  font-size 14px
  line-height 16px
  font-weight 600
  color #000

  &__imgContainer
    position relative
    width calc(100% - 2px)
    padding-top calc(100% - 2px)
    overflow hidden
    border-radius 4px
    background url('/images/album.svg')
    background-size cover
    box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)
    border 1px solid rgba(0, 0, 0, 0.1006)
    margin-bottom 8px
  &__img
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  &__title
    max-width 100%
    overflow hidden
    text-overflow ellipsis

  a:hover &
    &__title
      color #5b1fd8