.module-layout
  #sortableFavorite
    .item
      &:hover
        .handle
          content ''
          position absolute
          left 5px
          top 0
          background url("/images/svg/sortable.svg") 50% 50% no-repeat
          width 15px
          height 100%
          &:hover
            cursor move
  .topLine.favoritePage
    .sort.favoritePage
      margin-left 20px
      a
        color #fff
        font-size 14px
        font-weight bold
        background-color #1cd397
        border-radius 25px
        padding 9px 12px
        margin 10px 0 20px
        white-space nowrap
        cursor pointer
        &:last-child
          img
            padding-bottom 4px
        &:not(:first-child)
          margin-left 10px
        &:hover
          background-color #14bd85
        &.activated_sort
          background-color #18b07f
  .plateItems.favoriteConf.favoritePage
    display block
    text-align start
    @media (max-width 1030px)
      text-align justify
    li
      max-width 200px
      margin-top 0
      margin-right 10px
      .mobile &
        width 145px
        height fit-content
        margin-right 0
        @media only screen and (max-device-width 350px) and (orientation portrait)
          width 130px
        a
          height auto
          width 145px
          &.cover
            height 145px
          @media only screen and (max-device-width 350px) and (orientation portrait)
            width 130px
            height auto
            &.cover
              height 130px
    .newPlaylist
      .newPlaylist_cover
        display flex
        justify-content center
        align-items center
        background-color #e1e1e1
        margin-bottom 4px
        width 200px
        height @width
        border-radius 5px
  .emptyTracks
    margin-left 27px
    p
      color #444
      margin 5px 0
      .linkColor
        img
          vertical-align middle
          border-radius 0
.tablet
  .module-layout
    .topLine.favoritePage
      .sort.favoritePage
        width 95%
        margin 0 auto
        overflow-x scroll
        @media (min-width 1000px)
          justify-content start
        a
          font-size 18px
          padding 12px 14px
          &:not(:first-child)
            margin-left 10px
          &:hover
            background-color #14bd85
          &.activated_sort
            background-color #18b07f
    .plateItems.favoriteConf.favoritePage
      margin-left 25px

.mobile
  .module-layout
    .topLine.favoritePage
      .sort.favoritePage
        display flex
        justify-content space-between
        width 100%
        margin 10px 0
        a
          width 40px
          height 40px
          display flex
          padding 0
          justify-content center
          align-items center
          &:not(:first-child)
            margin-left 10px
          &:hover
            background-color #14bd85
          &.activated_sort
            background-color #18b07f
    .emptyTracks
      margin-left 0
    .plateItems.favoriteConf.favoritePage,
    .imageCollection
      display flex
      justify-content space-between
      flex-wrap wrap
      &:after
        display none
      .collections_items
        width 145px
        margin-right 0
        @media (max-width 350px)
          width 130px
        .cover
          width 145px
          height @width
          @media (max-width 350px)
            width 130px
            height @width
      .collections_item
        width 100px
        height @width
      .newPlaylist
        .newPlaylist_cover
          width 145px
          height @width
          @media only screen and (max-device-width 350px) and (orientation portrait)
            width 130px
            height @width