$search-submit-bg-color = #1d1d1d
$search-submit-text-color = #fff


.box
  padding 0 5px
  clear both
  @media (min-width 400px)
    padding 0 10px
  @media (min-width 600px)
    padding 0 20px
  h3
    font-size 18px
    font-weight bold
    a
      color #000
  h2
    font-size 18px
    font-weight bold
    a
      color #000
  a.linkColor
    text-decoration underline
  .item.main
    display flex
    flex-flow row wrap
    align-items flex-start
    justify-content space-between
    background linear-gradient(225deg, #4D32C8 0%, #1CD397 100%)
    border-radius 4px
    height 100%
    width 100%
    @media (min-width 900px)
      flex-wrap nowrap

    &._search
      background transparent
      margin-left 10px
      .right
        display flex
        flex-direction column
        max-width unset
        margin-top 0
        margin-bottom 10px
        margin-left 0
        h1
          color #000
    .mobile &
      max-height unset
    h1
      display block
      flex 1 1 100%
      float left
      font-size 2em
      line-height 44px
      color #fff
      word-break break-word
      margin 0 0 6px
      width 90%
      overflow hidden
      .tablet &
        width 97%
      .mobile &
        line-height 35px
        height auto
      @media (max-width 600px)
        margin-right 0
        margin-top 10px
        margin-left 0
        font-size 34px
        height unset
        word-break break-word
      @media (max-width 400px)
        font-size 1.6em
    h2
      display block
      flex 1 1 100%
      float left
      font-size 2em
      line-height 44px
      color #fff
      word-break break-word
      margin 0 0 6px
      width 90%
      overflow hidden
      .tablet &
        width 97%
      .mobile &
        line-height 35px
        height auto
      @media (max-width 600px)
        margin-right 0
        margin-top 10px
        margin-left 0
        font-size 34px
        height unset
        word-break break-word
      @media (max-width 400px)
        font-size 1.6em
  .left
    img
      width 168px
      height @width
      margin 15px
      -webkit-border-radius 5px
      -moz-border-radius 5px
      border-radius 5px
      @media only screen and (max-device-width 736px) and (orientation portrait)
        width 130px
        height @width
        margin-right 0
      @media only screen and (max-device-width 350px) and (orientation portrait)
        width 100px
        height @width
    .collectionButtonLine
      margin-left 15px
    &.collections_item
      margin 0!important
      .cover
        width 180px!important
        height @width
        margin 10px
        .audio_collections_grid_cover_0,
        .audio_collections_grid_cover_1,
        .audio_collections_grid_cover_2
          top 10px
          left @top
          .mobile &
            top 6px
            left @top
        .audio_collections_grid_cover_1
          top 19%
          left @top
          .mobile &
            top 25%
            left @top
        .audio_collections_grid_cover_2
          top 48%
          left unset
          right 10px
  .right
    max-width 664px
    width 100%
    height @width
    margin-top 15px
    margin-bottom 25px
    .tablet &
      max-width 540px
    .mobile &
      max-width unset
      margin 0 15px 15px
      display flex
      float unset
      width 100%
      @media (max-width 350px)
        margin 0 10px 10px
      .button.mobileShare
        background url("/images/svg/share.svg") 50% 50% no-repeat
        border none
        width 25px
        height 35px
      .this_collection
        margin-right 10px
        .audio_collections_grid
          &:after
            border-radius 5px
          img
            margin 0
          .audio_collections_grid_cover_bg
            filter unset
            border none
        .audio_collections_grid_cover_0,
        .audio_collections_grid_cover_1,
        .audio_collections_grid_cover_2
          .mobile &
            top 6px
            left @top
        .audio_collections_grid_cover_1
          .mobile &
            top 25%
            left @top
        .audio_collections_grid_cover_2
          .mobile &
            top 45%
            left unset
            right 6px
        img
          width 100%
          height @width
          min-width unset
      img
        margin 0 10px 0 0
        width 100px
        border 1px solid #fff
        height @width
        min-width @width
        border-radius 5px
      .mobileHead
        .collectionButtonLine
          display flex
          .button
            margin-right 0
            &.favorite
              padding 2px 7px 0
    @media only screen and (max-device-width 736px)
      margin-top 15px
      max-width 55%
      margin-bottom @margin-top
    @media only screen and (max-device-width 736px) and (orientation landscape)
      margin-top 20px
    &._search
      .mobile &
        margin 10px 0
      h1
        margin 0 0 0 20px

.plateItems
  clear both
  text-align justify
  font-size 0
  line-height .1em
  padding 0
  margin 0
  > *
    font-size 1rem
    line-height 1.2em
    display inline-block
    vertical-align top
  &:after
    content ''
    display inline-block
    width 100%
  &.widgetRadio
    h2
      width 100%
  &.favorieRadio
    text-align start
    @media (max-width 1030px)
      text-align justify
    @media (max-width 750px)
      margin 0
    li
      margin 0 10px 15px 0
      @media (max-width 750px)
        margin 0 0 10px 0
        max-width 145px!important


._search_items
  .plateItems
    text-align unset
    .mobile &
      text-align justify
      justify-content space-between
      flex-wrap wrap
    li
      margin 0 34px 60px 0!important
      height 200px
      overflow unset
      .tablet &
        margin 0 32px 60px 10px!important
      .mobile &
        margin 0 0 10px!important
        width 145px
        @media (max-width 350px)
          width 130px
      .cover
        margin-bottom 0
      .title
        padding-top 0
        margin-top 5px
      &.collections_items
        height 200px!important

.plateItems,
.plateItemsTitle
  margin 5px
  @media (min-width 400px)
    margin 10px 10px
  @media (min-width 600px)
    margin 10px 10px 0 20px
.plateItemsTitle
  margin-top 15px

.kinetic
  position relative
  .kineticLeft
    left 0
  .kineticRight
    right 0
    transform rotate(180deg)
  .kineticRight
  .kineticLeft
    position absolute
    top 27%
    z-index 10
    width 11px
    height 19px
    padding 20px
    background url("/images/svg/back.svg") 50% 50% no-repeat
    opacity 0.3
    cursor pointer
    .box__category &
      top 33%
    .mobile &
      visibility hidden

  .kineticCenter
    overflow hidden
  .kineticList
    padding 0 !important
    white-space nowrap
    font-size 0
    position relative
    list-style none
    margin 0
    > *
      white-space normal
  .plateItems
    &:after
      display none
    .item
      margin-left 24px
      &:first-child
        margin-left 0
      .mobile &
        margin-left 20px
    .title
      color #4D32C8

  &.is-scroll-right
    .kineticRight
      opacity 1
  &.is-scroll-left
    .kineticLeft
      opacity 1

a.moreButton
  float right
  letter-spacing: .2px;
  display: inline-block;
  text-decoration: none;
  opacity: .7;
  padding: 5px 10px 4px;
  background: $search-submit-bg-color;
  color: $search-submit-text-color;
  border-radius 2px
  &:hover
    opacity 1

.plateItems
  li
    width 200px

  .item
    position relative
    width 100%
    max-width 200px
    .genres&
      max-width 200px
    padding 0
    display inline-block
    background none
    overflow hidden
    margin 15px 0 10px
    text-align left
    font-size 12px
    font-weight normal

    .cover
      position relative
      display block
      border-radius 6px
      line-height 0

      img
        width 100%
        height 100%
        border-radius 5px
        min-width 200px
        min-height 200px
        .genres&
          border-radius 5px
          min-width 98px
          min-height 98px

    .title
      display inline-block
      padding-top 14px
      line-height 1rem
      color #000
      font-size 14px
      font-weight bold
      max-height 45px
      overflow hidden
      text-overflow ellipsis
      max-width 100%
      &:hover
        color #5b1fd8
        cursor pointer

    &.clear
      height 0
      margin 0
      overflow hidden
    &:hover
      .cover
        border-color #15002b
      a
        color #15002b
.mobile
  .plateItems
    .item,
    .collections_items
      max-width 145px
      min-height 180px
      @media only screen and (max-device-width 414px) and (orientation portrait)
        max-width 180px
      @media only screen and (max-device-width 385px) and (orientation portrait)
        max-width 155px
      @media only screen and (max-device-width 320px) and (orientation portrait)
        max-width 140px
      a
        .cover
          height auto
          img
            min-height 130px
            min-width 130px
      .title
        padding-top 2px
        font-size 12px
        max-height (1.25em * 2)
    &.imageCollection
      .collections_items
        max-width unset!important


$coverHeight = 200px
$maxTitleHeight = 40px
$itemHeight = $coverHeight + $maxTitleHeight
$itemVerticalMargin = 5px

.box.tile
  .plateItems
    display flex
    flex-wrap wrap
    justify-content space-between
    max-height ($itemHeight+$itemVerticalMargin*2)*2
    overflow hidden
    position relative
    font-size 0
    .item
      height $itemHeight
      margin 0 8px 20px 0
      a
        font-size 14px
        color #000
        font-weight bold
        display block
        .cover
          height $coverHeight
          border none
    .nextItem
      display block
      height 235px
      width 202px
      background white
      @media only screen and (max-width 851px) and (orientation landscape)
        height 150px
        width 145px
        margin 0 4px 10px 0
      @media only screen and (max-width 500px) and (orientation portrait)
        height 150px
        width 145px
        margin 0 4px 10px 0
      @media (max-width 350px)
        width 130px
      a
        display flex
        flex-direction column
        border-radius 8px
        font-weight bold
        width 200px
        height 200px
        color #fff
        background linear-gradient(225deg, #4D32C8 0%, #1CD397 100%)
        justify-content center
        align-items center
        @media only screen and (max-width 851px) and (orientation landscape)
          width 145px
          height @width
        @media only screen and (max-width 500px) and (orientation portrait)
          width 145px
          height @width
        @media (max-width 350px)
          width 130px
          height @width
        span
          display flex
          flex-direction column
          font-size 18px
          min-width 100px
          text-align center
          &:after
            content ''
            background url("/images/svg/nextItem.svg") 50% 50% no-repeat
            height 23px
            padding-top 12px
  &.widget-sidebar
    padding 0 10px 0 0
    margin-top 20px
    .plateItemsTitle
      margin 0 0 8px
      h3
        margin 0
    .plateItems
      max-height unset
      margin 0
      .item
        max-width 130px
        height 190px
        .cover
          height 130px
          width @height
          box-shadow 0 5px 10px rgba(0, 0, 0, 0.150136)
          img
            min-width 130px
            min-height @min-width
            border-radius 5px
        .title
          color #333
  .mobile &
    .plateItems
      max-height 375px
      .item
        max-width 145px
        height 180px
        margin 0
        a
          .cover
            height auto
            img
              min-height 145px
              min-width 145px
              @media only screen and (max-device-width 350px) and (orientation portrait)
                min-height 130px
                min-width 130px
