.player
  z-index 11
  position fixed
  width 100%
  height 56px
  top 0
  background #4D32C8


  .container
    max-width ($site-width - 44)
    height 100%
    display flex
    flex-flow row nowrap
    justify-content center
    align-items center

    .player-controls
      flex-grow 1
    .logo
      flex-grow 0
      height 100%
      width unset
      margin 0
      img
        margin 0
        padding 5px
        height 100%
        box-sizing border-box


    //@media only screen and (max-width : 600px) and (orientation: portrait)
    //  padding 0 14px


  &.playerDown
    bottom 0
    top unset
    height 70px
    transition height .5s
    &._open
      height 101px
      transition unset
      @media only screen and (min-device-width 320px) and (max-device-width 850px) and (orientation landscape)
        height 60px
      @media only screen and (max-device-width 350px)
        height 90px
      @media (orientation landscape)
        height 60px

  .containerDown
    height 100%

.collapsed_player
  position fixed
  display none
  height calc(100% - 90px)
  bottom 100px
  width 100%
  background #4D32C8
  z-index 30
  @media only screen and (min-device-width 320px) and (orientation landscape)
    bottom 60px
  @media only screen and (max-device-width 450px)
    bottom 100px
  @media only screen and (max-device-width 350px)
    bottom 90px
  @media (orientation landscape)
    bottom 60px
  &.open_player
    display block !important
    @media (orientation landscape)
      display flex !important
      justify-content space-between
      height calc(100% - 60px)
  .arr_player
    position absolute
    float right
    right 30px
    top 40px
    transform rotate(180deg)
  .playerImg
    display flex
    align-items center
    justify-content center
    width 100%
    height 70%
    min-height 260px
    margin-top 70px
    filter drop-shadow(0 10px 40px rgba(0, 0, 0, 0.45))
    @media only screen and (max-device-width 450px)
      height 65%
    @media only screen and (max-device-width 375px)
      height 55%
    @media only screen and (max-device-width 350px)
      min-height 240px
    @media (orientation landscape)
      width 50%
      min-height 200px
      margin-top 0
    img
      width 355px
      height @width
      @media only screen and (max-device-width 450px)
        width 350px
        height @width
      @media only screen and (max-device-width 400px)
        width 320px
        height @width
      @media only screen and (max-device-width 375px)
        width 270px
        height @width
      @media only screen and (max-device-width 350px)
        width 220px
        height @width
      @media (orientation landscape)
        width 150px
        height @width
  .bottom_line
    display flex
    position fixed
    align-items start
    width 86%
    margin 10px 30px
    bottom 110px
    justify-content space-between
    @media only screen and (min-device-width 320px) and (orientation landscape)
      position unset
      width unset
      margin 70px 40px
    @media only screen and (min-device-height 800px)
      bottom 150px
    @media only screen and (max-device-height 800px)
      bottom 110px
    @media only screen and (max-device-width 450px)
      bottom 130px
    @media only screen and (max-device-width 375px)
      margin 10px 25px
    @media only screen and (max-device-width 350px)
      margin 0 20px
      width 88%
    @media (orientation landscape)
      width 70%
      bottom 60px
      margin-right 100px
    .track-nameStr
      display flex
      flex-direction column
      width 100%
      max-width 250px
      color white
      .artist
      .track
        width 100%
        max-height 55px
        overflow hidden
      .artist
        font-size 24px
        font-weight 700
        margin-bottom 15px
        line-height 27px
        @media only screen and (max-device-width 350px)
          font-size 18px
          line-height 20px
          margin-bottom 5px
      .track
        font-size 18px
        max-height 40px
        @media only screen and (max-device-width 350px)
          font-size 16px
    .download
      .dl
        display block
        background url(/images/svg/dl_player-mobile.svg) 50% 50% no-repeat
        width 40px
        height @width

.player-controls
  height 100%
  display flex
  align-items center
  flex-flow row nowrap
  justify-content space-around
  max-width 1130px
  .group.track-name, .group.additional
    width 40%
  .group-mobile
    display flex
    align-items center
    width 90px
    .playerImg
      margin-left 10px
  .group-mobile-collapsed
    display none
    justify-content space-between
    width 86%
    &.visible
      display flex
      .repeat
      .shuffle
        background url("/images/svg/repeat_player-mobile.svg") 50% 50% no-repeat
        width 40px
        height @width
        &.active
          background url("/images/svg/repeat_player-mobile__active.svg") 50% 50% no-repeat
      .shuffle
        background url("/images/svg/shuffle_player-mobile.svg") 50% 50% no-repeat
        &.active
          background url("/images/svg/shuffle_player-mobile__active.svg") 50% 50% no-repeat
      .prev
      .next
        background url("/images/svg/prev_player-mobile.svg") 50% 50% no-repeat
        width 40px
        height @width
      .next
        background url("/images/svg/next_player-mobile.svg") 50% 50% no-repeat
    .play
    .pause
      background url('/images/svg/play_player-mobile.svg') 50% 50% no-repeat
      width 40px
      height @width
      margin 0 15px!important
    .pause
      background url('/images/svg/pause_player-mobile.svg') 50% 50% no-repeat
  .playerImg
    font-size 0
    line-height 0
    display inline-block
    vertical-align middle
    margin-right 10px
    opacity 0
    width 40px
    height @width
    img
      width 40px
      height @width
      border-radius 4px

  .group
    display flex
    flex-flow row nowrap
    align-items center
    &.track-name
      max-width 520px
      width 100%
      .mobile &
        max-width 70%
        @media only screen and (min-device-width 320px) and (max-device-width 850px) and (orientation landscape)
          max-width 80%
        @media only screen and (max-device-width 350px)
          max-width 230px
      .inited&
        opacity 0
      .mobile &
        width 100%
        .arr_player
          display none
          width 30px
          height @width
          margin-right 10px
      .track-nameStr
        display flex
        flex-flow column nowrap
        flex-direction column
        align-items baseline
        color #DBE2EA
        font-size 16px
        padding 0 5px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        max-width 520px
        .tablet &
          max-width 420px
        .mobile &
          width 100%
        .track,
        .artist
          .mobile &
            text-overflow ellipsis
            width 100%
            max-width 210px
            overflow hidden
            white-space nowrap
            padding-left 1px
            @media only screen and (min-device-width 320px) and (max-device-width 850px) and (orientation landscape)
              max-width 90%
        .track
          opacity 1
        .artist
          font-weight bold
    &.additional
      width 240px

    &.additional
      justify-content space-around

    &.basic
      font-size 1px
      line-height 0
      margin: 0 20px

    &.basic,
    &.additional
      > *
        cursor pointer

    &.additional
      @import "responsive"

  .prev
    background url(/images/sprite2_mp3ha.svg) 7px 9px
    width 30px
    height @width
    &:hover
      background url(/images/sprite2_mp3ha.svg) 7px -51px

  .next
    background url("/images/sprite2_mp3ha.svg") -172px 9px
    width 30px
    height @width
    &:hover
      background url("/images/sprite2_mp3ha.svg") -172px -51px

  .play,
  .pause
    width 18px
    height 20px
    margin 0 10px
    .mobile &
      margin 0
      display inline-block

  .download
    .dl:not([href]), .inited&
      opacity 0
      filter unquote('alpha(opacity=0)')
    .dl
      display block
      background url("/images/sprite2_mp3ha.svg") -360px 0px
      width 29px
      height 29px
      &:hover
        background url("/images/sprite2_mp3ha.svg") -360px -60px

  .play
    background url("/images/sprite2_mp3ha.svg") -53px 7px
    width 30px
    height @width
    @media (min-width 850px)
      &:hover
        background url("/images/sprite2_mp3ha.svg") -53px -53px
    .playing&
      display none

  .pause
    background url("/images/sprite2_mp3ha.svg") -113px 7px
    width 30px
    height @width
    @media (min-width 850px)
      &:hover
        background url("/images/sprite2_mp3ha.svg") -113px -53px
    .playing&
      inline-block()

    //@media only screen and (max-width : 780px)
    //  margin 0 55px 0 270px

  .playerImg
    //margin-left auto;
    & + .track-name
      margin-left 0;

  .mute
    display block
    cursor pointer
    height 29px
    width 29px
    background url(/images/sprite2_mp3ha.svg) -480px -0px

    &:hover
      background url(/images/sprite2_mp3ha.svg) -480px -60px

    &.active
      background url(/images/sprite2_mp3ha.svg) -480px -120px


  .volume-bar
    cursor pointer
    padding 0
    overflow hidden
    border-radius 10px
    display inline-block
    width 74px
    height 6px
    background #232323
    @media (max-width: 1024px)
      display none

    @media only screen and (max-width : 750px)
      width 60px

    .volume-bar-value
      cursor pointer
      background #30DDA3
      width 90%
      height 7px
      border-radius 10px
      transition width 80ms linear,height 80ms linear

  .repeat
    cursor pointer
    width 29px
    height 29px
    background url("/images/sprite2_mp3ha.svg") -240px -0px
    &.active
      background url("/images/sprite2_mp3ha.svg") -240px -60px
    @media (min-width 850px)
      &:hover
        background url("/images/sprite2_mp3ha.svg") -240px -60px

  .shuffle
    cursor pointer
    width 29px
    height 29px
    background url("/images/sprite2_mp3ha.svg") -300px 0px
    &.active
      background url("/images/sprite2_mp3ha.svg") -300px -60px
    @media (min-width 850px)
      &:hover
        background url("/images/sprite2_mp3ha.svg") -300px -60px


.progress
  position absolute
  width 100%
  bottom 0
  left 0
  height 11px
  transition min-height linear 0.3s
  background #232323
  &.playerProgressBottom
    bottom 100%
  &.pr_open
    width 87%
    left 7%
    bottom unset
    top -30px
    border-radius 5px
    z-index 999
    .currentTime
    .timeLeft
      position absolute
      padding-left 0!important
      padding-right 0!important
      top 25px
      font-weight 500
      font-size 14px
      @media only screen and (min-device-width 320px) and (max-device-width 850px) and (orientation landscape)
        top 15px
    .timeLeft
      right 0
    .play-bar
      border-radius 5px

  .seek-bar
    &>span
      position relative
      font-size 12px
      color #fff
      z-index 10
      line-height 12px
    .currentTime
      float left
      padding-left 10px
    .timeLeft
      padding-right 10px
      float right

  .track-name
    margin-left 10px
    position relative
    pointer-events none
    @media(min-width : 766px)
      display none

  .play-bar
    inline-block()
    position absolute
    top 0
    height 100%
    border-bottom-right-radius 5px
    border-top-right-radius 5px
    left 0
    background #30DDA3
    @media (max-width 1024px)
      height 100%
      border none


  .handler
    width 18px
    height 18px
    position absolute
    top 0
    border none
    background #ff0273
    margin-top -4px
    margin-left -9px
    z-index 2
    //transition margin-top linear 0.2s
    @media(max-width : 1024px)
      display none

  @media(min-width : 1025px)
    &:hover
      .seek-bar
        height 10px

      .handler
        margin-top -3px

.mobile
.tablet
  .player-controls
    .additional
      width 124px
      .mute
        display none
    .progress
      .seek-bar
        height 10px
      .handler
        margin-top -1px