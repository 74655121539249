body.mobile,
body.tablet
  user-selection()

  //.logo
  //  @media all and (max-width : 780px)
  //    margin-right 0.6em

  .player

    @import "player"

  @import "search"
  @import "layout"