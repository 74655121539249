.overlay
  position fixed
  top 0
  left 0
  z-index 5
  background #000000
  opacity 0.6
  cursor pointer
  filter unquote('alpha(opacity=80)')
  width 100%
  height 100%

.modal
  width 300px
  padding 20px
  position fixed
  top 50%
  margin-top -120px
  left 50%
  margin-left -150px
  z-index 6
  border-radius 4px
  background #ffffff
  color #000000
  line-height 1.5em

  h3
    margin-top 0
    line-height 1.5em

  .js-captcha-input,
  img
    margin-bottom 1.8em

  .modal-footer
    a
      background $basic_yellow
      color #000000
      border-radius 4px
      padding 6px 9px
      text-decoration none
