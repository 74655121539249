.module-advantages
  background #1a2d32
  margin-top -0.5em
  color white
  padding 20px 10px

  ul
    display flex
    flex-flow row wrap
    justify-content space-around
    align-items flex-start

    .advantage
      display flex
      flex-flow column nowrap
      align-items center
      justify-content center
      text-align center
      max-width 180px
      margin 20px 0

      h3
        color #47e49c
        min-height 2.3em

      img
        height 48px