.mixies

  .mix
    overflow hidden

    .cover
      float: left;
      margin: 0 12px 1em 0;
      max-width 100px

      img
        width 100%

      @media all and (max-width : 750px)
        float: none;
        margin: 0 0 1em 0;
        display block
        text-align center

    @media all and (max-width : 750px)
      width 23%
      float: left
      margin-right 2%
      font-size 0.8em
      min-width 100px