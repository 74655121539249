ul.genres-box
  li
    overflow hidden
    display inline-block
    width 30%
    //min-width 200px

    a
      letter-spacing 0.2px
      display block
      text-decoration none
      background transparent
      color #000
      &:hover
        color #00aeef

    a
      .iconGenres
        width 89px
        height 89px
        vertical-align middle
        margin-right 10px
        background url(/images/genre.png) no-repeat
        background-size contain
      //&:hover
      //  i.iconGenres
      //    background url(/images/genre.png) no-repeat
      //    background-size contain


.desktop-sidebar
  ul.genres-box
    li
      display block
      width auto

.module-popular
  padding 10px 15px 20px
  display flex
  ul
    margin 0 auto
    .collapsed
      &:after
        content 'Все'
        font-weight bold
        inline-block()
        border-radius(4px)
        overflow hidden
        letter-spacing 0.2px
        inline-block()
        text-decoration none
        padding 5px 10px 4px
        background #e5e5e5
        color #638b11


      li
        display none
        &.pinned
          display unset
    /*li a
      background linear-gradient(#de5858, #e05353)*/
  &.customGenres
    margin-bottom -36px
    padding 0 20px
    & + .content
      padding-top 70px
      padding-bottom 50px
      @media (max-width : 500px)
        padding-top 30px

  &.customGenres ul
    display flex
    flex-flow row wrap
    justify-content space-around
    align-items flex-start
    width 100%
    z-index 2
    @media (max-width : 500px)
      margin-bottom 20px
    li
      height 66px
      overflow hidden
      border-radius 35px
      background-color #5b1fd8
      display flex
      align-items stretch
      justify-content stretch
      margin 3px 3px
      &:hover,
      &.activated
        background-color #1CD397
      a
        display flex
        flex-direction row
        align-items center
        justify-content start
        padding 10px 20px
        background-color transparent
        width 100%
        .titleSmallDim
          display none
        .image
          margin-right 10px
          height 30px
          @media (max-width : 500px)
            display none
          img
            width 20px
            height 30px
        .title, .titleSmallDim
          margin 10px 0
          font-size 15px
          font-weight bold
          line-height 1.53
          letter-spacing 0.3px
        .posContainer
          display flex
          flex-direction column
          align-items center
          text-align center
          max-width 300px
          @media (max-width : 500px)
            display none
          .description
            margin 10px 0
            font-size 14px
            line-height 1.29
            letter-spacing normal
    @media(max-width : 1100px)
      //flex-direction row-reverse
      justify-content stretch
      li
        flex-basis 30%
        max-width unset
        min-width 250px
        flex-grow 1
        @media (max-width : 500px)
          height 40px
          min-width 100px
          flex-basis 32%
          a
            span.titleSmallDim
              width auto
        &:last-child
          flex-grow 1
        a
          justify-content center
    @media(max-width : 800px)
      justify-content stretch
      li
        flex-basis 49%
        a
          .titleSmallDim
            display inline
            width 115px
          .title
            display none

.sort.mobile
  display none
.sort
  display flex
  align-items center
  min-width 380px
  width 100%
  &.favoritePage
    width 100%
    display flex
    align-items center
  a
    margin-bottom 9px
  .popular,
  .title,
  .release_date
    display inline-block
    vertical-align middle
    margin-left 27px
    font-size 15px
    padding 0
    width 160px
    height 32px
    border none
    cursor pointer
    line-height 20px
    font-weight 700
    color #fff
    background #190A5C
    &:first-child
      border-top-left-radius 35px
      border-bottom-left-radius 35px
      margin-right -5px
    &:last-child
      border-top-right-radius 35px
      border-bottom-right-radius 35px
    &:active
      text-decoration none
    &.activated_sort
      background #1cd397
      &:hover
        color #190A5C
    &:hover
      color #1cd397
    &:last-child
      margin-left 0
    .tablet &
      margin-left 0
      width 150px
  .tablet &
    min-width 300px
  .mobile &
    min-width unset
    width unset
    .popular,
    .title,
    .release_date
      display none
    .dropdown-sort
      display none

.mobile
  .sort.mobile
    display inline-block
    margin-top -8px
    >*
      font-size 14px
      padding 9px 4px
      line-height 20px
      font-weight 700
      text-transform uppercase
      display inline-block
      color #fff
      background-color #190a5c
      img
        vertical-align middle
        margin 0 5px
        width 18px
        height 18px
      &:first-child
        border-top-left-radius 35px
        border-bottom-left-radius 35px
        margin-right -5px
      &:last-child
        border-top-right-radius 35px
        border-bottom-right-radius 35px
      &:hover
        opacity .8
      &:active
        text-decoration none
      &.activated_sort
        background-color #1cd397
        &:hover
          opacity .8