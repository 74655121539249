$whiteTransparent = rgba(255, 255, 255, 0.2)

.footer
  //horizontal-centered()
  position relative
  background #190A5C
  display flex
  flex-direction column
  flex-wrap wrap
  justify-content space-between
  color white
  padding 55px 0 100px
  @media (max-width 1100px)
    padding 55px 20px 80px
  .contacts
    display flex
    justify-content space-between
    a
      text-decoration underline
      color white
  .seo-text
    font-size 14px
    line-height 18px
    margin-top 0
    opacity .6
    span
      font-weight bold
  .footerContent
    display none
    @media (min-width 800px)
      display block
      overflow hidden
      margin 30px 20px 10px
    .footerCollections
      margin-top 10px
      width 100%
      a
        color #E5E5E5
      .listItems
        column-count 4
        @media (max-width 1100px)
          column-count 2
        ul
          @media (max-width 1100px)
            margin-bottom 40px
        .widgetLink
          display block
          text-decoration none
          font-weight bold
          font-size 18px
          line-height 22px
          margin-bottom 5px
          &:hover
            color #edfff9
            cursor pointer
        li
          border-top 1px solid $whiteTransparent
          padding 5px 0
          vertical-align middle
          font-weight 500
          font-size 14px
          &:last-child
            border-bottom 1px solid $whiteTransparent
          .widgetBlock
            display flex
            align-items center
            .collectionImg
              height 60px
              margin-right 10px
              img
                width 60px
                height @width
                border-radius 4px
            .title
              text-decoration none
              &:hover
                color #edfff9

  h3,
  h4,
  h5
    margin-top 0

  &:before
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 35px;
    width: 100%;
    background: white;
    box-sizing: unset;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;

.mobile
  .footer
    padding 20px 10px 80px
    flex-flow column nowrap
    &:before
      content none