@import "logo"
@import "../player/index"

.header
  max-width $site-width
  /*min-width 320px*/
  margin 0 auto
  padding 20px 0 5px
  display flex
  flex-flow row nowrap
  justify-content space-between
  @media (max-width 450px)
    flex-flow column
  &.toFixed
    position fixed
    z-index 1000
    width 100vw
    background-color #190A5C

.mobile
  .header
    position sticky
    width 100%
    height 74px
    top 0
    background #190a5c
    padding 0
    flex-flow row nowrap
    justify-content space-around
    align-items center
    z-index 30
  #hamburger-icon
    width 35px
    height 40px
    position relative
    display block
    .line
      display block
      background #ecf0f1
      width 25px
      height 4px
      position absolute
      left 4px
      margin-top 7px
      transition all 0.4s
      -webkit-transition all 0.4s
      -moz-transition all 0.4s
      &.line-1
        top 0
      &.line-2
        top 20%
      &.line-3
        top 40%
    &.active
      .line-1
        transform translateY(10px) translateX(0) rotate(45deg)
        -webkit-transform translateY(10px) translateX(0) rotate(45deg)
        -moz-transform translateY(10px) translateX(0) rotate(45deg)
      .line-2
        opacity 0
      .line-3
        transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -webkit-transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -moz-transform translateY(-6px) translateX(0) rotate(45deg * -1)


