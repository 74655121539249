.module-text,
.module-downloaded,
.module-popular
.module-collections
  overflow hidden
  margin-bottom 0.5em
  padding 0 20px
  padding-left 0
  padding-bottom 20px


  h3
    color #000
    margin 5px 0 12px
    font-size 1.2em
    font-weight bold

  ul
    li
      border-radius(4px)
      overflow hidden
      float left
      margin 3px

      a
        letter-spacing 0.2px
        inline-block()
        text-decoration none
        padding 5px 10px 4px
        background #4D32C8
        color #fff
        filter unquote('alpha(opacity=70)')

        &:hover
          background #7B5EFF
          opacity 1
          filter unquote('alpha(opacity=100)')
  .songs
    .item.sidebarItem
      margin 0 5px
      padding 5px 0
      border-radius 0
      border-top 1px solid #00000014
      font-size 14px
      &:first-child
        border none
      .count
        min-width 30px
        margin-right 5px
        text-align center
        line-height 50px
        &.chart_down
          background url(/images/chartDown.svg) 50% 90% no-repeat
        &.chart_up
          background url(/images/chartUp.svg) 50% 10% no-repeat
        &.chart_new
          background url(/images/chartNew.svg) 50% 50% no-repeat
      &.active.pause
        .actions
          ul
            .playlistImg
              div.play
                background url(/images/popular/playTop.svg)
      &.active
        .actions
          ul
            li.playlistImg
              background none
              img
                display block
              div.play
                display block
                background url(/images/popular/pauseTop.svg)
      &.load
        .actions
          ul
            .playlistImg
              .play
                display block
                border-radius 20px
                background url(/images/spiner.gif)
      .desc
        width 100%
        .desc_wrapper
          white-space nowrap
          width 90%
          line-height 15px
          overflow hidden
          span
            display inline-block
            width 100%
            white-space initial
            height 1.1em
            overflow hidden
      .actions
        ul
          .playlistImg
            position relative
            width 60px
            height @width
            .play
              width 20px
              height 20px
              position absolute
              display none
              background url(/images/popular/playTop.svg)
              top 50%
              left 50%
              margin-right -50%
              transform translate(-50%, -50%)
            img
              width 100%
            &:hover
              background none
              img
                display block
              .play
                display block