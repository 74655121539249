unless EXCLUDE_ZVOOQ
  .songs
    .playOther
      width: 24px;
      height: 24px;
      background: url('/images/playOther.png') 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: bottom;
      margin-right: 5px;
      a
        display inline-block
        height 100%
        width 100%


//.item
//  .rbt
//    display none !important
//
//.rbtCont
//  //margin-top -7px
//  .rbt:hover
//    margin-top -6px
//
//.rbt
//  background url(/images/rbt/phoneActive.png) 50% 50% no-repeat
//  width 24px
//  height 24px
//  display: inline-block
//  &:hover
//    background #bfcf97 url(/images/rbt/phoneActive.png) top 50% left 4px no-repeat
//    position relative
//    z-index 10
//    width 104px
//    height 36px
//    margin-right -80px
//    margin-top -4px
//    margin-bottom -2px
//    border-radius 0 3px 3px 0
//    overflow hidden
//    &:after
//      content 'Поставить на гудок'
//      position absolute
//      right 25px
//      top 4px
//      width 50px
//      color #fff
//      font-weight bold
//      font-size 12px
//      line-height 14px
//      text-align right

//.mobile
//  .rbt
//    background #bfcf97  url(/images/rbt/phoneActive.png) top 50% left 4px no-repeat
//    position relative
//    width 104px
//    height 36px
//    margin-top 0
//    margin-bottom 0
//    vertical-align middle
//    border-radius 0 3px 3px 0
//    overflow hidden
//    z-index auto
//    &:hover
//      margin-left 0
//      z-index auto
//    &:after
//      z-index auto
//      content 'Поставить на гудок'
//      white-space normal
//      position absolute
//      right 25px
//      top 4px
//      width 50px
//      color #fff
//      font-weight bold
//      font-size 12px
//      line-height 14px
//      text-align right
//  .actions
//    li
//      vertical-align middle


//.item.active
//  .rbt
//    background transparent url(/images/rbt/phone.png) 50% 50% no-repeat
//    &:hover
//      background #bfcf97  url(/images/rbt/phoneActive.png) top 50% left 4px no-repeat
//
//
//.hide-rbt
//  display none !important
//
//.rbt-offer
//  margin-bottom 20px

//.mobile
//.tablet
//  .item.active
//    .rbt
//      background #bfcf97  url(/images/rbt/phoneActive.png) top 50% left 4px no-repeat
//      &:hover
//        background #bfcf97  url(/images/rbt/phoneActive.png) top 50% left 4px no-repeat


:not(.mobile)
:not(.tablet)
  .songs
    .item.itemsPaddingLeft
      overflow visible



.songs
  .itemRight
    display none
    @media (min-width 480px)
      display inline-block

//.mobile
//.tablet
//  .itemsPaddingLeft.songs
//    .item
//      padding-left 3px

.zvukButton
  display inline-block
  border 1px solid #ccc
  width 30px
  height 30px
  border-radius 50%
  overflow hidden
  margin-left 5px
  &__icon
    display inline-block
    margin 5px 0 0 8px

  &_download &
    &__icon
      margin 7px 0 0 7px


