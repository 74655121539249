.module-letters
  padding 11px 15px 5px 20px

  @media only screen and (max-width : 500px) and (orientation: portrait)
    padding 11px 15px 4px 8px

  .opener
    display none

  .letters-list
    justified()
    line-height: 1em;
    font-size: 0;
    margin-bottom 10px

    li
      vertical-align: top;
      font-size: 0.9rem;
      line-height: 1.2em;
      padding 2px 3px 1px
      margin 0 3px

      a
        color #0a9e80

        &:hover
          text-decoration underline

    @media all and (max-width : 700px)
      text-align left

  @media only screen and (max-width : 400px)
    border-bottom 1px solid #7f7f7f

    .letters-list
      height 0
      overflow: hidden
      margin-bottom 0

    &.open
      .letters-list
        height auto
        margin-top 10px

    .opener
      padding 0 6px
      display: block
      color #7f7f7f
      background: url('data:image/gif;base64,R0lGODlhCwAHALMLAMvLy/v7+9zc3MzMzLm5ufz8/MPDw/b29tHR0evr67Ozs////wAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwMTQgNzkuMTU2Nzk3LCAyMDE0LzA4LzIwLTA5OjUzOjAyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNCAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDQwOEQ4Q0JFOUEwMTFFNEIwQTdDQ0ExQzUxMjMzRjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDQwOEQ4Q0NFOUEwMTFFNEIwQTdDQ0ExQzUxMjMzRjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENDA4RDhDOUU5QTAxMUU0QjBBN0NDQTFDNTEyMzNGOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENDA4RDhDQUU5QTAxMUU0QjBBN0NDQTFDNTEyMzNGOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAAAsALAAAAAALAAcAAAQdEKlJKSqmUlPWIRpxLGSiJWQqUELqDsrgugEQuBEAOw==') right center no-repeat

