.module-layout

  .songs

    .item
      min-height 38px
      margin 4px 0
      padding 0
      .artist
        opacity 0.7
        filter unquote('alpha(opacity=70)')

      .actions
        left 12px

      /*
        .play
          background url(/images/mp3xa_sprite.svg) -34px 0px

          &:hover
            background url(/images/mp3xa_sprite.svg) -34px -191px

          &:focus,
          &:active
            background url(/images/mp3xa_sprite.svg) -76px -191px

      &.active

        .play
          background url(/images/mp3xa_sprite.svg) -76px -84px

          &:focus,
          &:hover,
          &:active
            background url(/images/mp3xa_sprite.svg) -76px -232px

        &.pause

          .play
            background url(/images/mp3xa_sprite.svg) -76px -84px

            &:active
              background url(/images/mp3xa_sprite.svg) -34px -233px
      */
