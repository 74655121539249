/* Slider */
.slick-slider
  position relative
  display block
  box-sizing border-box
  -webkit-touch-callout none
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  -ms-touch-action pan-y
  touch-action pan-y
  -webkit-tap-highlight-color transparent
  li
    list-style none
.slick-list
  position relative
  display block
  margin 0
  padding 0
  &:focus
    outline none
  &.dragging
    cursor pointer
    cursor hand
.slick-slider .slick-track,
.slick-slider .slick-list
  -webkit-transform translate3d(0, 0, 0)
  -moz-transform translate3d(0, 0, 0)
  -ms-transform translate3d(0, 0, 0)
  -o-transform translate3d(0, 0, 0)
  transform translate3d(0, 0, 0)
.slick-track
  position relative
  left 0
  top 0
  display block
  margin-left auto
  margin-right auto
  &:before,
  &:after
    content ""
    display table
  &:after
    clear both
  .slick-loading &
    visibility hidden
.slick-slide
  float left
  height 100%
  min-height 1px
  padding 0
  margin 0
  [dir="rtl"] &
    float right
  img
    display block
    width 100%
    max-width 200px
    height 100%
    max-height 200px
  &.slick-loading img
    display none
  display none
  &.dragging img
    pointer-events none
  .slick-initialized &
    display block
  .slick-loading &
    visibility hidden
  .slick-vertical &
    display block
    height auto
    border 1px solid transparent
.slick-arrow.slick-hidden
  display none


$slick-arrow-color = white
$slick-dot-color = #4D32C8
$slick-dot-color-active = $slick-dot-color
$slick-prev-character = ""
$slick-next-character = ""
$slick-dot-size = 6px
$slick-opacity-default = 0.75
$slick-opacity-on-hover = 1
$slick-opacity-not-active = 0.25

.slick-prev,
.slick-next
  position absolute
  display block
  height 27px
  width 20px
  line-height 0
  font-size 0
  cursor pointer
  color transparent
  top 50%
  -webkit-transform translate(0, -50%)
  -ms-transform translate(0, -50%)
  transform translate(0, -50%)
  padding 0
  border none
  outline none
  z-index 1
  &:hover, &:focus
    outline none
    &:before
      opacity $slick-opacity-on-hover
  &.slick-disabled:before
    opacity $slick-opacity-not-active
  &:before
    font-size 20px
    line-height 1
.slick-prev
  left 25px
  background url("/images/svg/back.svg") 50% 50% no-repeat
  [dir="rtl"] &
    left auto
    right 25px
.slick-next
  right 25px
  top 43%
  background url("/images/svg/back.svg") 50% 50% no-repeat
  transform rotate(180deg)
  [dir="rtl"] &
    left 25px
    right auto

/* Dots */
.slick-dotted.slick-slider
  margin-bottom 30px
.slick-dots
  position absolute
  bottom -40px
  list-style none
  display block
  text-align center
  padding 0
  margin 0
  width 100%
  li
    position relative
    display inline-block
    height 20px
    width 20px
    margin 0
    padding 0
    cursor pointer
    button
      border 0
      background $slick-dot-color
      border-radius 50%
      display block
      height 8px
      width 8px
      color transparent
      opacity .5
      outline none
      padding 0
      cursor pointer
      &:hover, &:focus
        outline none
      &:before
        position absolute
        top 0
        left 0
        content ''
        width 20px
        height 20px
    &.slick-active button
      background $slick-dot-color-active
      opacity 1


.sliderSlick
  margin-left -10px
  margin-right -10px
  padding 5px 0
  width calc(100% + 20px)
  .mobile &
    margin 0
    margin-left -10px
    width calc(100% + 20px)
  &__item
    margin 0 10px
    aspect-ratio 1
  &:not(.slick-initialized)
    max-width calc(100% + 20px)
    white-space nowrap
    overflow hidden
    font-size 0
    line-height 0
  &:not(.slick-initialized) &
    &__item
      display inline-block
      max-width calc(50% - 20px)
  &_related
    .slick-prev
      top 41%
    .slick-next
      top 36%

  .mobile &
    margin 0
    margin-left -10px
    width calc(100% + 20px)

// mangling access
.slickSlider_autoplay,
.sliderSlickMain
  z-index 1
