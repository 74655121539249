//.radioTop
//  padding 0 25px
//  box-sizing content-box
//  .left
//    position relative
//    width 200px
//    height @width
//    margin 20px 0
//    img
//      width 100%
//      height 100%
//      border-radius 5px
//    .radioButtonLine
//      position absolute
//      top 0
//      left 0
//      bottom 0
//      right 0
//      background-color rgba(25,10,92,26%)
//      border-radius 5px
//      a
//        display block
//        height 100%
//        border-radius 5px
//        i
//          display block
//          position absolute
//          top 50%
//          left 50%
//          margin-right -50%
//          transform translate(-50%, -50%)
//          width 16px
//          height 18px
//          background url("/images/sprite2_mp3ha.svg") -60px -60px
//        &:hover
//        &.pause
//          background #190a5cb3
//          i
//            background url("/images/sprite2_mp3ha.svg") -60px -60px
//        &.play
//          background #190a5cb3
//          i
//            width 16px
//            height 16px
//            display block
//            background url("/images/sprite2_mp3ha.svg") -120px -60px
//        &.loading
//          background #190a5cb3
//          i
//            width 21px
//            height 21px
//            border-radius 21px
//            display block
//            background url("/images/spiner.gif")
//  .main
//    display flex
//    align-items center
//    flex-wrap wrap
//    justify-content space-between
//    h3
//      font-size 30px
//      font-weight bold
//      display inline-block
//      margin 10px 0
//  .plateItems
//    margin auto 0
//    h3
//      font-size 18px
//      font-weight bold
//      display block
//      margin 0
//  @media (max-width 600px)
//    .main
//      h3
//        font-size 20px
//    .left
//      width 150px
//      height 150px
//    .plateItems
//      h3
//        font-size 15px
//      li.item
//        max-width 140px
//        margin 5px 0 10px
//        .cover
//          img
//            min-width 140px
//            min-height 140px
//        span.title
//          white-space nowrap
//          max-height 18px
//          font-size 12px
//
//  .widgetRadio
//    border-top 1px solid rgba(0, 0, 0, 0.2)
//    padding-top 20px

.standUpWrapper
  h1
    margin 20px 19px 13px
  .plateItems
    .item
      height 165px
      > div
        max-height 56px
      &.clear
        height 0