.artists-list
  padding 7px 15px 8px 20px
  justified()

  @media only screen and (max-width : 500px) and (orientation: portrait)
    padding 7px 15px 8px
    font-size 0.8em

  ul
    width 32%
    vertical-align top

    li
      text-align left
      margin-bottom 0.3em

      @media all and (max-width : 500px)
        margin-bottom 0.5em

      a
        text-align left
        color #000000

        &:hover
          text-decoration underline