.module-layout
  padding 0


.module-downloaded,
.module-popular
  //margin-bottom 20px

  h3
    margin 24px 0 12px

  ul

    li
      margin-bottom 3px

      a
        padding 9px 8px 9px

    &:after
      cursor pointer
      padding 9px 10px 9px
      vertical-align top

