@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

.swiper-slide
  margin-right 8px
  width unset
  &:last-child
    margin-right 0
  img
    width 100%
    max-width 200px
    height 100%

.swiper-button-next,
.swiper-button-prev
  position absolute
  display block
  height 27px
  width 20px
  line-height 0
  font-size 0
  cursor pointer
  color transparent
  top 50%
  -webkit-transform translate(0, -50%)
  -ms-transform translate(0, -50%)
  transform translate(0, -50%)
  padding 0
  border none
  outline none
  z-index 1
  &:hover, &:focus
    outline none
    &:before
      opacity $slick-opacity-on-hover
  &:before
    font-size 20px
    line-height 1
.swiper-button-prev
  left 25px
  background url("/images/svg/back.svg") 50% 50% no-repeat
  [dir="rtl"] &
    left auto
    right 25px
.swiper-button-next
  right 25px
  top 43%
  background url("/images/svg/back.svg") 50% 50% no-repeat
  transform rotate(180deg)
  [dir="rtl"] &
    left 25px
    right auto