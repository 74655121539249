@charset "utf-8"

@import "../ad/index"
@import "../reset"
@import "../settings"
@import "../mixins"
@import "../header/index"
@import "../footer/index"
@import "../popular/index"
@import "../mixies/index"
@import "../song-items/index"
@import "../artists/index"
@import "../pagination"
@import "../responsive/index"
@import "../modal"
@import "../auth/index"
@import "../radio/index"
@import "../plate"
@import "../letters"
@import "../search/index"
@import "../advantages.styl"
@import "../genres"
@import "../menu"
@import "../playlist"
@import "_common/styl/suggests"
@import "../blocks/catItem"
@import "../responsive/index"
@import "../swiper.styl"
@import "styl/slider/slick"

button, select
  outline none

.hide
  display none !important

html
  height: 100%;

body
  margin 0
  font-size 15px
  line-height 20px
  color #000
  background #190a5c
  background-position 22% 11%
  background-size 123%
  background-attachment fixed

html,body
  font-family Arial,sans-serif;

a
  color #5b1fd8;
  text-decoration none

.container
  max-width $site-width
  //min-width 320px
  horizontal-centered()

  .content
    z-index 1
    //margin-top 73px
    background #fff
    //overflow hidden
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    //margin-top -100px
    //padding-top 80px
    min-height 500px
    display grid
    grid-template-columns 1fr
    @media (min-width 900px)
      grid-template-columns 1fr 340px
    .span.fullWidth
      @media (min-width 900px)
        grid-template-columns 1fr
      overflow unset
  &.playerDown
    margin-bottom 65px


    @import "../search/index"

    //.mobile &
    //  margin-top 74px

  $sidebarWidth = unit(300px / $site-width * 100, '%')
  $desktopWidth = 100% - $sidebarWidth
  $sidebarMargin = 0px
  .desktop
    overflow hidden
    //float left
    width 100%
    //grid-template-columns 1fr 340px

    //max-width 892px
    //
    //@media (min-width 900px)
    //  width calc(100% - 340px)

    &.fullWidth
      width 100%
      max-width @width

    .tablet &
      width 100%

  .mobile
    overflow hidden
    float left
    width 100%

    @media only screen and (max-width : 768px)
      float none
      width auto
      margin-right 0

  .desktop-sidebar
    display none
    //float right
    //width 320px
    margin-left 20px
    @media (min-width 900px)
      display block

    .closeBanner
      cursor pointer
      display block
      width 24px
      height 24px
      float right

    .closeBanner.show
      background: url("/images/close.png") 0 0 no-repeat

    @media all and (max-width : 768px)
      float:none
      width auto
      padding 7px 15px 8px 20px
      margin-left 0

    @media all and (max-width : 500px)
      padding 7px 15px 8px

    .songs
      .item
        .play
          background url(/images/sprite2_mp3ha.svg) -55px -116px
          width 40px
          height @width

          &:active,
          &:hover
            background url(/images/sprite2_mp3ha.svg) -55px -176px
        &.active
          .actions
            .play
              background url(/images/sprite2_mp3ha.svg) -115px -116px

          &.pause
            .actions
              .play
                background url(/images/sprite2_mp3ha.svg) -55px -176px

.box:not(.box__slider)
.songs
  background #fff
  overflow hidden
  padding 0 10px 10px
  margin-bottom 10px !important
  &.genresHeader,
  &.radioHeader
    .audio_collections_grid
      &:after
        display none
      .audio_collections_grid_cover_bg
        display none
  &.tracksHeader,
  &.genresHeader,
  &.radioHeader
    .right,
    .mobileHead
      .subTitle
        display block
        color white
        letter-spacing .07em
        text-transform uppercase
        font-style normal
        font-weight 900
        font-size 11px
        margin 0
      .subGenre
        display block
        margin-top 11px
        font-size 14px
        line-height 16px
        color white
        .mobile &
          margin-top 3px
        a
          color inherit
          text-decoration underline

    .radioDescription
      display inline-block
      margin 0 0 13px
      font-weight normal
      font-size 14px
      line-height 18px
      width 90%
      color white
      opacity .6
  &.oneSong
    background transparent
    margin-bottom 0 !important
    padding 0
  .mobile &
    .tracksHeader
      padding 0

.box
  &__slider
    overflow hidden
    margin-left 24px
    padding 2px
    .mobile &
      margin 0
    .shared_buttons
      margin 0
  &__title
    margin-bottom 24px

  &__category
    margin-bottom 18px
    .slick-list
      overflow visible

  &__category &
    &__title
      display inline-block
      margin-top 0
      font-size 32px
      font-weight 700
      line-height 32px
      text-align left

  .mobile &
    &__category
      .box
        &__title
          margin-top 20px
          margin-bottom 10px
          font-size 24px

  .mobile &
    &__slider
      margin 0
      padding 0
    &__category
      margin 0 10px
      &__title
        margin 10px 0 5px
        font-size 28px

.songHeader
  margin-top 15px

.box
  .songs
    padding 0
    margin-bottom 0 !important

.left
  float left
  margin 0
  @media only screen and (max-width 851px) and (orientation landscape)
    margin-top 0!important
  .mobile &
    width 100%
    margin 15px 15px 10px
    @media (max-width 350px)
      margin 10px 10px 5px
    .mobileHead
      display flex
      width 100%
      flex-direction column
      h1
        width 100%
        margin-top 0
        margin-bottom 0
      .collectionButtonLine
        margin-left 0
.collapsedBox
  max-height (35 * 16)px
  line-height 0
  position relative
  overflow hidden
  &.collapsedBoxMin
    max-height (35* 3)px
  &:after
    content ''
    position absolute
    bottom 0
    left 0
    width 100%
    height 35px
    background #fff
    display block
    z-index 1
  &:before
    content 'Показать все'
    position absolute
    bottom 1px
    right 5px
    font-size 15px
    line-height 15px
    padding 6px 0
    width 150px
    text-align center
    background #30dda3
    color #fff
    border-radius 4px
    z-index 2
    cursor pointer
  &.collapsedBoxOpen
    max-height initial
    padding-bottom 35px
    &:before
      content 'Скрыть'
  li
    font-size 14px
    line-height 0
    max-width 100%
    overflow hidden
    margin-bottom 7px
    a
      overflow hidden
      max-width 100%
      box-sizing border-box
      text-overflow ellipsis
      white-space nowrap
      line-height 19px

.collectionButtonLine,
.genreButtonLine,
.radioButtonLine
  .mobile &
    font-size 20px
    @media (min-width 350px)
      display flex
      align-items start
  .button
    display inline-block
    color rgb(255, 255, 255)
    text-align center
    cursor pointer
    max-width 222px
    min-height 33px
    line-height 28px
    box-sizing border-box
    background #fff
    margin 2px 0
    padding 2px 10px 0
    border-radius 16px
    text-decoration none
    border-width 2px
    border-style solid
    border-color #fff
    border-image initial
    font-size 16px
    font-weight bold
    .mobile &
      line-height 24px
      margin-right 2px
      margin-bottom 10px
    &.outline
      background inherit
      color #fff
      .mobile &
        border none
        .favoriteIco
          display block
          background url("/images/svg/favorite_like_white_head.svg") 50% 50% no-repeat
          width 30px
          height @width
      &:after
        content 'Добавить в Мою Музыку'
        .mobile &
          content ''
    &.outline.activated
      background #1CD397
      border none
      line-height 32px
      color #fff
      .mobile &
        background unset
        .favoriteIco
          display block
          background url("/images/svg/favorite_like_head.svg") 50% 50% no-repeat
          width 30px
          height @width
      &:after
        content 'Убрать из моей музыки'
        .mobile &
          content ''
      @media only screen and (max-device-width 736px) and (orientation landscape)
        line-height 32px
      @media only screen and (max-device-width 736px) and (orientation portrait)
        min-width unset
    &.radioOutline
      background inherit
      color #fff
      width 150px
      &:after
        content 'Слушать радио'
    &.radioOutline.playing
      &:after
        content 'Приостановить'
    &.listen
      line-height 32px
      background #1CD397
      border-radius 16px
      width 100px
      margin-right 15px
      border unset

.radioButtonLine
  .button
    &.listen
      width 150px
      @media (min-width 350px)
        margin 0 5px 0 0
    &.favorite
      background inherit
      color #fff
      &:after
        content 'Добавить в Мою Музыку'
        .mobile &
          content ''
      @media(min-width 750px)
        &.activated
          background #1CD397
          border none
          line-height 32px
          color #fff
          &:after
            content 'Убрать из моей музыки'
      .mobile &
        border none
        padding 0
        .favoriteIco
          display block
          background url(/images/svg/favorite_like_white_head.svg) 50% 50% no-repeat
          width 30px
          height @width
        &.activated
          .favoriteIco
            background url(/images/svg/favorite_like_head.svg) 50% 50% no-repeat
    &.mobileShare
      @media (min-width 350px)
        margin 0

//@media (max-width 500px)
//  margin-left 0
//  margin-top 0
//  clear both
//  .left ~ &//иначе селектор выше перекрывает специализацию этого
//    margin-left 0
//    margin-top 0
//    clear both

.emptyTracks
  p
    display none
    margin 0

//branding
body.branding
  height: auto !important;
  position: relative !important;
  &:not(.vk-download)
    .skin-block
      background-position center 60px !important
  .container
    overflow hidden
    .content
      //background: url(/images/bg.png) #4c4c4c!important;
      background #fff !important
      position: relative !important;
      margin-top: 285px !important;
      @media (min-width 600px)
        //background #fff !important
        margin-top: 385px !important;
  .footer
    position relative
    z-index 1

body.branding
  &:not(.vk-download)
    .skin-block
      background-position center 70px !important
  &.vk-download
    .skin-block
      background-position center 110px !important
  .container
    .content
      margin-top: 240px !important;
      @media (min-width 600px)
        margin-top: 385px !important;

.collapsedButton
  li
    display none !important
    &.pinned
      display unset !important
    &.moreButtonCollapsed
      display block !important
      text-align center !important
      span
        display inline-block !important


.messageContainer
  color black
  position absolute
  display flex
  text-align center
  width 100%
  left 0
  top calc(100% + 15px)
  z-index 50
  .message
    background white
    border black solid 1px
    padding 2px 5px
    margin 0 auto

.containerDown
  .messageContainer
    bottom 100%
    top unset

.noScroll
  overflow hidden

.seo-text
  padding 5px 25px 25px

.mobile
  .content
    border-radius 0

.asideHeader
  margin 5px 0 12px
  line-height 1.2
  h2 &, &
    font-size 20px
    font-weight bold

