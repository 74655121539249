.module-search
  //background #c8c8c8
  //width: 100%;
  padding 11px 0 0
  flex-grow 1.4
  margin-right 40px
  @media (max-width 1030px)
      margin-right 0

  @media only screen and (max-width : 500px) and (orientation: portrait)
    padding 10px 15px 10px

  form
    position relative
    .inInputSearch
      overflow hidden
      background #fff
      width 100%
      border-radius 35px
      .reset
        position absolute
        top 11px
        right 110px
        border none
        display none
        width 30px
        height @width
        background url('/images/svg/reset.svg') 50% 50% no-repeat
        transition background 200ms
        outline none
        cursor pointer
        .mobile &
          top 2px
          right 33px
      input
        &:valid
          & ~ .reset
            display block
      input
        font-weight 500
        display block
        width calc(100% - 135px)
        height 52px
        line-height 2.8em
        font-size 1.07em
        padding-left 15px
        padding-right 10px
        outline none
        border none
        box-shadow none
        text-indent 8px
        color #333
        &::-webkit-input-placeholder {color:#000000;}
        &::-moz-placeholder          {color:#000000;}/* Firefox 19+ */
        &:-moz-placeholder           {color:#000000;}/* Firefox 18- */
        &:-ms-input-placeholder      {color:#000000;}
        .mobile &
          width calc(100% - 60px)
      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button,
      input::-webkit-search-results-button,
      input::-webkit-search-results-decoration
        display none
    .search_button
      position absolute
      height 100%
      display inline-block
      right 0
      top 0
      width 96px
      min-width 55px
      border-radius: 0px 35px 35px 0px
      -webkit-border-radius 0px 35px 35px 0px
      -moz-border-radius 0px 35px 35px 0px
      border none
      background-color #1CD397
      z-index 9
      @media (max-width : 500px)
        width 92px
      button
        cursor pointer
        background none
        border none
        color #ffffff
        font-size 1.07em
        line-height 2em
        font-weight bold
        width 100%
        height 100%
        @media (max-width : 500px)
          position absolute
          background url('/images/mp3xa_sprite.svg') -153px -127px no-repeat
          width 27px
          height 20px
          top 50%
          left 50%
          margin-right -50%
          transform translate(-50%, -50%)
          span
            display none
      @media (max-width : 1000px)
        right 0
    &:after
      content ""
      display table
      clear both

.mobile
  .module-search#searchElement
    max-width 33px
    height 33px
    overflow hidden
    border-radius 35px
    padding 0
    input
      height 33px
      @media (max-width 750px)
        line-height 20px
    .search_button
      min-width 33px
      max-width @min-width
      border-radius 50%
      button
        position absolute
        background url('/images/mp3xa_sprite.svg') -153px -127px no-repeat
        width 27px
        height 20px
        top 50%
        left 50%
        margin-right -50%
        transform translate(-50%, -50%)
        span
          display none
    &.active
      max-width 80%
  .displayNone
    display none