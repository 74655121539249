@import "favorites"

.userRight
  display flex
  align-items center
  justify-content space-between
  margin-left 30px
  @media (min-width 1100px)
    &.auth
      min-width 305px
  .mobile &
    margin-left 0
  .myMusic
    display flex
    align-items center
    margin-right 30px
    cursor pointer
  img
    width 35px
    height @width
    margin 0 8px 0 0
    display inline-block
    vertical-align middle
    border-radius 9999px
  span
    display inline-block
    vertical-align middle
    font-size 16px
    font-weight bold
    color #FFFFFF
    @media(max-width 500px)
      margin-left 5px
    .myMusic__mobile
      font-size 18px

body
  &.tablet
    .myMusic
      img
        width 50px
        height @width
        margin 0
    .dropdownUserMenu
      top 90px
  &.mobile
    .myMusic
      margin-right 0
      .login, .logout
        margin-left 0
      img
        width 33px
        height @width
        margin 0

.dropdownUserMenu
  position absolute
  background-color #fff
  width 117px
  right 0
  top 65px
  font-size 14px
  text-align left
  border none
  border-radius 5px
  display none
  z-index 999
  box-shadow 0 4px 20px rgba(0, 0, 0, 0.25)
  span
    font-weight 500
    font-size 14px
    line-height 30px
    width 80%
    padding-left 15px
    color #000
    a
      display block
      color #000
      &:active
        color #000

#authModal
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 9999
  background #0008
  display none
  .openModal &
    display block

  $padding = 25px
  .container
    display block
    position relative
    width 100%
    height unset
    box-sizing border-box
    top 50px
    max-width 375px
    padding 42px 39px 41px 36px
    background white
    border-radius 5px
    font-size 18px
    font-weight 700
    .close
      position absolute
      top 20px
      right @top
      cursor pointer
      padding 10px
      margin - (@padding)
    .title,
    .title__buttons,
    .title__last-login
      color #000
      line-height 20px
    .title
      margin-bottom 20px
    .title__buttons,
    .title__last-login
      font-size 16px
      line-height 19px
      margin-bottom 10px
    .title__buttons
      margin-top 30px

  .authButton
    display block
    position relative
    height 50px
    width 300px
    line-height @height
    border-radius 5px
    margin-bottom 20px
    color white
    font-size 16px
    font-weight 300
    background lightgray//fallback
    &:hover
      opacity 0.9
    &:active
      box-shadow inset 0 0 3px 0 #000
      text-decoration none
    &:after
      content ''
      display block
      position absolute
      top 0
      left @height
      height 100%
      width 1px
      background #0003
      box-shadow 1px 1px 0 0 #fff2
    img
      margin 8px
      margin-right (@margin + 15px)
      width (@height - 2 * @margin)
      height (@height - 2 * @margin)
      float left
      border-radius 50%
    &.vkauth
      background #39739b
    &.mailauth
      background #005ff9
    &.okauth
      background #ec7f21
    &.yandexauth
      background #DB0D0D

  .mobile &
    background none
    .container
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      border-radius 0
      max-width unset
      padding $padding
      @media (orientation: landscape)
        max-height 100vh
        max-width 100%
        overflow scroll
    .authButton
      width unset
      font-size 14px

.module-auth
  max-width 400px
  margin 0 auto 40px
  padding 0 19px

  .input
    display block
    width 100%
    margin-bottom 10px
    padding 13px 10px
    -moz-box-sizing border-box
    box-sizing border-box
    border 2px solid #c8c8c8
    border-radius 4px
    background #fff
    font-size 1.05em

  .btn
    display block
    width 100%
    max-width 200px
    padding 13px 10px
    -moz-box-sizing border-box
    box-sizing border-box
    border 0
    border-radius 4px
    background url(/images/gradient.jpg) bottom left repeat-x
    color #000
    font-size 1.05em
    cursor pointer

  label
    display inline-block
    margin-bottom 25px

.module-text
  padding 0 19px

.authError
  color red
  text-align center