.box.burger_menu
  display none
ul.menu-box
  position fixed
  z-index 999
  background-color #190A5C
  margin 0
  padding 0
  top 73px
  bottom 0
  left 0
  right 0
  li
    display block
    width 100%
    height 40px
    border-top 1px solid #4D32C8
    a
      font-size 17px
      font-weight bold
      padding-left 10px
      color #fff
      line-height 40px


.countsActive
  background-color #00b8ed

.countsTransition
  transition background-color 1s

.box.burger_menu.down
  display block