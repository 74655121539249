.text-logo
  display block
  width 120px
  height 34px
  font-size 1.6em
  color #000000
  font-weight bold
  margin-top 5px
  text-decoration none
  padding 14px 0 0 65px
  background url('/images/mp3Ha_logo.png') no-repeat

  @media all and (max-width : 780px)
    background url('/images/sprite.png') -201px -180px no-repeat
    /*width 40px*/
    font-size 1.5em
    word-wrap break-word
    text-align center
    line-height 1.4em
    padding 9px 0 4px 39px

  @media all and (max-width : 400px)
    width 130px
    font-size 1.5em

  @media all and (max-width : 360px)
    width 80px
    font-size 1.5em

  span
    font-weight normal

  &:active,
  &:hover
    text-decoration none


.logo_playerDown
  margin-top 0
  align-self flex-end
  flex-grow 0.1
  margin-right 40px
  @media (max-width 1030px)
    margin-right 0
    height 100%
.logo
  @media (max-width 450px)
    margin 0 auto

  a
    display block
    text-align center
    img
      margin 11px 5px 5px
      object-fit contain
      @media (max-width : 400px)
        margin 0
        width 80%
.mobile
  .header
    .logo
      align-self center
      max-width 124px
      height 55px
      margin 0
      flex-grow 0
      img
        margin 0
        width 100%