.catItem
  display flex
  flex-direction column
  align-items center
  position relative
  width 100%
  height 100%
  &__img
    border-radius 4px
    max-width 100%
    height 100%
  &__title
    display block
    font-size 14px
    font-weight bold
    line-height 16px
    padding 10px 0

  &__container
    display block
    width 100%
    text-decoration none
    outline none
    height 100%
  &:hover &
    &__img
      box-shadow 0 0 0 2px rgba(25, 10, 92, 0.35)


  &_big &
    &__title
      position absolute
      color #fff
      font-size 18px
      font-weight 900
      line-height 20px
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.25);
      left 16px
      bottom 16px
      margin-right 10px
      padding 0

  .mobile &
    &_big
      .catItem
        &__title
          font-size 14px
          font-weight 600
          @media (min-width 360px)
            font-size 16px
