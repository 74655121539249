.module-layout
  .playlistPage &
    padding 0
    background none
    @media(max-width 500px)
      padding 25px 0 0!important
    .headPlaylist
      padding-bottom 20px
      @media (max-width 500px)
        padding 0 10px 10px
    .module-search-playlist
      .inInputSearch
        display flex
        width 95%
        margin 10px auto
        #search
          width 90%
          height 35px
          border 2px solid #1cd397
          border-top-left-radius 20px
          border-bottom-left-radius 20px
          padding 0 10px 0 20px
          &:focus
            outline none
            border 2px solid #18b07f
            border-top-left-radius 20px
            border-bottom-left-radius 20px
        .dropdown
          width 10%
          min-width 60px
        button
          height 35px
          width 100%
          background-color #1cd397
          border-top-right-radius 20px
          border-bottom-right-radius 20px
          color #FFFFFF
          font-weight bold
          border none
    .searchPlaylist_popup,
    .addedTracks
      .songName
        margin-right auto
        width 85%
        @media (max-width 500px)
          max-width 55%
      li.item
        .songName
          .artist
            &:after
              content " — "
              margin 0 5px
        .playlistImg
          display none
        .play
          sprite2Crop(1,2)
          margin-right 10px
          width 60px
          height @width
        &.active
          .play
            background url(/images/sprite2_mp3ha.svg) -105px -108px
            margin-right 10px
          &.pause
            .play
              background url(/images/sprite2_mp3ha.svg) -42px -108px
    .addedTracks
      .mobile &
        margin 10px -10px 20px -10px
  .songs
    margin 30px 0 0
    overflow initial
    .item
      position relative
      display flex
      align-items center
      padding 0 20px
      margin 0 0 8px 5px
      height 60px
      width unset
      cursor pointer
      flex-flow row nowrap
      overflow initial
      > *
        flex-shrink 0

      &:nth-child(-n+3)
        .inPlaylists
          .dropdown-playlists
            top 50px!important
            bottom unset !important
      &:nth-child(3)
        .inPlaylists
          .dropdown-playlists
            top 0 !important
      &:nth-last-child(-n+7)
        .inPlaylists
          .dropdown-playlists
            top unset
            bottom 10px
      &:hover
        box-shadow 0 1px 6px rgba(30,123,92,.314)
        background-color #edfff9
      .play
        background url(/images/sprite2_mp3ha.svg) -42px -108px
        margin 0 10px
        padding 0
        width 60px
        height @width
      .desc
        flex-shrink 1
        overflow hidden
        white-space nowrap

        //max-width calc(100% - 240px)
        //.mobile &
        //  max-width calc(100% - 170px)
        //  @media(max-width 360px)
        //    max-width calc(100% - 140px)
        //&.auth
        //  .mobile &
        //    max-width calc(100% - 210px)
        //    @media(max-width 360px)
        //      max-width calc(100% - 175px)

        .artist
          overflow hidden
          text-overflow ellipsis
          display inline-block
          max-width 100%

      &.activeFirstLine
        background #EDFFF9
        box-shadow 0 1px 6px rgba(30, 123, 92, 0.4)
        border-radius 4px

    .favorite
      width 28px
      height 28px
      margin-right 6px
      &.favoriteIco
        display inline-block
        background url("/images/svg/like.svg") 50% 55% no-repeat
      &.favoriteIco.activated
        background url("/images/svg/like_active.svg") 50% 55% no-repeat
    .addedTracks
      margin 10px 0 20px
      .item
        padding 5px 102px 5px 13px
      .inPlaylists
        display none
  .unstyled.songs
    .item
      .actions
        display flex
        align-items center
        margin-right 10px
        //width 60px
        //height @width
        .play
          margin -10px
        .play.playlistImg
          margin 0
          img
            width 60px
            height @width
    .remove_playlist_track
      background url(/images/remove_playlist.png) no-repeat
      width 29px
      height 29px
      margin 0 5px
    .inPlaylists
      background url(/images/sprite2_mp3ha.svg) -360px -119px
      width 30px
      height @width
      margin 0 6px 0 0
      &:hover
        background url(/images/sprite2_mp3ha.svg) -360px -179px
      .mobile &
        width 31px
        background-size unset
      .dropdown-playlists
        position absolute
        width 100%
        max-width 280px
        max-height 305px
        height fit-content
        top 10px
        right 100px
        background #fff
        color #000
        box-sizing border-box
        box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
        border-radius 5px
        font-size 13px
        text-align left
        display none
        z-index 9
        .mobile &
          right 20px
          &:nth-last-child(-n+6)
            bottom 50px
            top unset
        ul
          margin-top 15px
          max-height 200px
          min-height 35px
          overflow-y auto
          scrollbar-color #5A5959 #FFFFFF
          scrollbar-width thin
          .mobile &
            max-height 120px
          &:before
            content 'Добавить в плейлист'
            display block
            font-weight bold
            font-size 16px
            line-height 24px
            margin 0 20px
            padding-bottom 10px
            border-bottom 1px solid #E5E5E5
          &::-webkit-scrollbar
            width 5px
          &::-webkit-scrollbar-thumb
            background-color #5A5959
          li
            font-weight bold
            font-size 14px
            line-height 40px
            padding-left 20px
            max-width inherit
            text-overflow ellipsis
            overflow hidden
            display block
            &:hover
              background #EFEFEF
            span
              display block
              max-width 180px
              text-overflow ellipsis
              overflow hidden
              white-space nowrap
        .create_playlist
          position relative
          font-weight bold
          font-size 14px
          line-height 30px
          margin 0 0 5px 20px
          padding 10px 0 5px 25px
          &:before
            content ''
            position absolute
            top 0
            left 0
            width 235px
            border-bottom 1px solid #E5E5E5
          span
            &:before
              content ''
              position absolute
              left 0
              top 15px
              width 20px
              height @width
              background url(/images/svg/create_Playlist.svg) 50% 50% no-repeat
  &.favoritePage,
  .imageCollection
    .playlist_item,
    .collections_items
    .collections_item
      display inline-block
      position relative
      width 100%
      max-width 200px
      padding 0
      margin 0 0 60px 0
      background none
      text-align left
      font-size 14px
      font-weight bold
      height 200px
      cursor pointer
      .cover
        width 200px
        height @width
        border-radius 8px
        position relative
        display block
        overflow hidden
        margin-bottom 5px
      .mobile &
        height 180px
        margin 0 4px 10px 0
        a
          display block
          position relative
    .collections_item
      @media only screen and (max-device-width 736px) and (orientation landscape)
        width 150px!important
        height @width
        margin 0!important
      @media only screen and (max-device-width 736px) and (orientation portrait)
        width 125px!important
        height @width
    li
      .title
        display block
        max-height 32px
        overflow hidden
        height 100%
        font-weight bold
        font-size 14px
        color #4D32C8
        &:hover
          cursor pointer
        .mobile &
          font-size 12px
          margin-top 5px
      &:hover
        .title
          color #4D32C8
  .newPlaylist
    .newPlaylist_cover
      display flex!important
      align-items center
      justify-content center
      background #E1E1E1
      border-radius 7px
      margin-bottom 5px
      width 200px
      height @width
      @media (max-width 1030px)
        width 145px
        height @width
      img
        width 70px
        height @width
  .mobile &
    .this_collection
      .audio_collections_grid
        @media (max-width 500px)
          width 135px!important
          height @width
        @media (max-width 350px)
          width 100px!important
          height @width
  .audio_collections_grid
    position relative
    @media only screen and (max-width 851px) and (orientation landscape)
      width 145px!important
      height @width
    @media only screen and (max-width 500px) and (orientation portrait)
      width 145px!important
      height @width
    @media (max-width 350px)
      width 130px!important
      height @width
    &:after
      content ''
      position absolute
      top 0
      left @top
      width 100%
      height @width
      background-color rgba(0,0,0,.4)
    .audio_collections_grid_cover_bg
      filter blur(5px)
      width 100%
      height @width
      margin 0
    .audio_collections_grid_cover_0,
    .audio_collections_grid_cover_1,
    .audio_collections_grid_cover_2
      position absolute
      width 84px
      height @width
      margin 0
      border-radius 3px
      box-sizing border-box
      border 1px solid #DBDBDB
      top 16px
      left @top
      z-index 1
      @media only screen and (max-width 851px) and (orientation landscape)
        width 65px!important
        height @width
      @media only screen and (max-width 500px) and (orientation portrait)
        width 65px!important
        height @width
      @media (max-width 350px)
        width 50px!important
        height @width
    .audio_collections_grid_cover_1
      width 110px
      height @width
      top 22%
      left @top
      z-index 2
      .mobile &
        top 30%
        left @top
    .audio_collections_grid_cover_2
      top 50%
      left unset
      right 16px
      z-index 1
  .audio_pl_grid
    top 0
    left 0
    width 100%
    height @width
    img
      width 200px
      height @width
      @media (max-width 1030px)
        width 100%
        height @width
    .newPlaylist_item
      background url("/images/album.jpg") 50% 50% no-repeat
      @media (max-width 1030px)
        width 200px
        height @width
      @media (max-width 750px)
        width 140px
        height @width
    .audio_pl_grid_cover_once
      width 100%!important
      height @width
    .audio_pl_grid_cover
      position absolute
      background-size cover
      background-position 50%
      width 50%
      height @width
      .mobile &
        width 50%
        height @width
    .audio_pl_grid_cover_1
      left 50%
    .audio_pl_grid_cover_2
      top 50%
    .audio_pl_grid_cover_3
      left 50%
      top @left
  .headerPlaylist
    display flex
    margin-left 27px
    margin-top 20px
    .mobile &
      margin-left 0
    .playlist_track
      position relative
      width 200px
      height @width
      @media (max-width 750px)
        width 140px
        height @width
        min-width @width
    .descriptionPlaylist
      margin-left 20px
      @media (max-width 750px)
        margin-left 10px
        width 100%
      .titlePlaylist
        display flex
        font-size 20px
        font-weight bold
        div
          max-width 400px
          border-radius 4px
          border 1px solid transparent
          color #000
          overflow hidden
          .mobile &
            width calc(100vw - 170px)
        span
          font-weight bold
          font-size 20px
          line-height 22px
        .edit_playlist
          margin-left 10px
          margin-right 10px
          width 24px
          height @width
          cursor pointer
          @media (max-width 500px)
            display none
        .edit_playlist-done
        .edit_playlist-err
          display none
        svg
          width 24px
          height @width
        path
          stroke-dasharray 99.47578430175781
          stroke-dashoffset -99.47578430175781
          fill transparent
        svg.animate path
          fill #5b1fd8
          opacity 1
        @keyframes draw
          0%
            opacity 1
            stroke-dashoffset -99.47578430175781
            fill transparent
            transform translateY(0)
          35%
            stroke-dashoffset 0
            fill transparent
          60%
            fill $palette1
            opacity 1
            transform translateY(0)
          100%
            fill $palette1
            stroke-dashoffset 0
            opacity 0
            transform translateY(-10px)
      .buttonsPlaylist
        display flex
        align-items center
        .button.listen
          display flex
          align-self center
          margin 15px 0
          max-width 106px
          padding 0 22px
          line-height 35px
          border-radius 10px
          background #5b1fd8
          color white
          &:before
            content ''
            display inline-block
            position relative
            left 0
            top 3px
            margin-right 5px
            background url(/images/sprite2_mp3ha.svg) -60px -54px
            width 18px
            height 24px
          &.disabled
            opacity .4
            user-select none
            cursor default
          @media (max-width 750px)
            padding 0 10px
        .remove_playlist
          background url("/images/svg/remove_playlist.svg") 50% 50% no-repeat
          background-color #5b1fd8
          width 36px
          height @width
          border-radius 10px
          margin-left 20px
          cursor pointer
          @media (max-width 1030px)
            margin-left 10px
      .bottomPlaylist
        display flex
        justify-content space-between
        .duration
          font-size 14px
          line-height 16px
          color #000
      .emptyTracks
        margin 0
        p
          font-weight 500
          font-size 14px
          line-height 16px
          color #454545
          @media (max-width 1030px)
            line-height 23px
      @media (max-width 350px)
        display block
  #sortable
    margin-top 15px
    padding 0
    p
      color #000
      padding 20px 20px 0
      font-size 16px
      span
        font-size 20px
        color #5b1fd8
    &.songs
      padding-bottom 10px
      @media (max-width 1030px)
        padding 0 10px 10px
    .item
      margin-right 0
      margin-left 0
      &:hover
        .handle
          content ''
          position absolute
          left 5px
          top 0
          background url("/images/svg/sortable.svg") 50% 50% no-repeat
          width 15px
          height 100%
          &:hover
            cursor move
      @media (max-width 1030px)
        padding 0
  .modal_removePlaylist
    display none
    position fixed
    z-index 1001
    left 0
    top 0
    width 100%
    height 100%
    overflow auto
    background-color rgba(0,0,0,0.4)
    @media (max-width 500px)
      padding 0
    .modal-content
      background #FFFFFF
      box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
      margin 15% auto
      padding 20px
      border-radius 5px
      height 186px
      width 50%
      max-width 510px
      @media (max-width 1030px)
        width 80%
      @media (max-width 750px)
        padding 0
        margin 40% auto
        height unset
        width 100%
    .close
      float right
      background url("/images/close.svg") 50% 50% no-repeat
      width 20px
      height @width
      cursor pointer
      @media(max-width 500px)
        width 30px
        height @width
    p
      display flex
      flex-direction column
      margin 0
      padding 10px 10px 0
      line-height 40px
      color #222222
      @media (max-width 750px)
        padding 40px 10px 0
        line-height 30px
      span
        &:first-child
          font-weight bold
          font-size 20px
        &:last-child
          font-weight 500
          font-size 16px
    .modal_buttons
      display flex
      justify-content flex-start
      margin 30px 0
      @media (max-width 750px)
        justify-content center
        padding 30px 10px
        margin 0
      @media (max-width 500px)
        justify-content start
      > *
        width 85px
        height 36px
        border-radius 7px
        box-sizing border-box
        font-weight bold
        font-size 14px
        line-height 35px
        text-align center
        cursor pointer
      .button_cancel
        color #5b1fd8
        margin-right 30px
      .button_delete
        background #5b1fd8
        color #fff