.pagination
  padding 0 20px
  margin-bottom 1.7em
  display flex
  justify-content center
  ul
    display flex
    li
      width 36px
      text-align center
      height 36px
      overflow hidden
      margin 0 1px
      border-radius 36px
      a
        text-decoration none
        display block
        width 100%
        height 100%
        text-align center
        line-height 36px
        color #000
        &.active,
        &:not(.arrow):hover
          background #30DDA3
          color #ffffff
      span
        line-height 36px
        font-size 24px
        &.ellipsis
          font-size 14px
        &.paginationArrowNoActive
          opacity .4
  @media (max-width 600px)
    ul
      li
        width 25px
        height 25px
        margin-right 2px
        span
          padding 0
          display block
          font-size 20px
          line-height 25px
        a
          padding 0
          line-height 25px
          font-size 13px

div.delimiter
  width 100%
  .pagination
    margin 10px 0


